<template>
  <div>

    <v-container>
      <!-- <Sidebar /> -->
      <HeaderPage />
      <BackComponent
        :title_name="$t('message.title-edit-account')"
        class="pt-7 mt-7"
      />

      <v-col class="pt-4 pr-2" cols="12" style="margin-bottom: 8rem">
        <v-form @submit.prevent="handleEditAccount">
          <v-row style="margin-top: 25px">
            <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
              <span class="label-class required">{{
                $t("message.label-name")
              }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
              <v-text-field
                v-model="formEdit.name"
                :error-messages="nameErrors"
                @input="$v.formEdit.name.$touch()"
                @blur="$v.formEdit.name.$touch()"
                required
                outlined
              />
            </v-col>
            <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
              <span class="label-class required">{{ $t("message.title-furigana") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
              <v-text-field
                v-model="formEdit.furigana"
                :error-messages="furiganaErrors"
                @input="$v.formEdit.furigana.$touch()"
                @blur="$v.formEdit.furigana.$touch()"
                required
                outlined
              />
            </v-col>
            <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
              <span class="label-class">{{
                $t("message.label-old-password")
              }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
              <v-text-field
                v-model="formEdit.oldPassword"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="oldPasswordErrors"
                name="password"
                autocomplete="on"
                required
                @input="$v.formEdit.oldPassword.$touch()"
                @blur="$v.formEdit.oldPassword.$touch()"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                outlined
              />
            </v-col>
            <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
              <span class="label-class">{{
                $t("message.label-new-password")
              }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
              <v-text-field
                v-model="formEdit.password"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                name="password"
                autocomplete="on"
                required
                @input="$v.formEdit.password.$touch()"
                @blur="$v.formEdit.password.$touch()"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                outlined
              />
            </v-col>
            <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
              <span class="label-class">{{
                $t("message.label-conf-password")
              }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
              <v-text-field
                v-model="formEdit.confirmPassword"
                :type="showConPassword ? 'text' : 'password'"
                :error-messages="confirmPasswordErrors"
                name="password"
                autocomplete="on"
                required
                @input="$v.formEdit.confirmPassword.$touch()"
                @blur="$v.formEdit.confirmPassword.$touch()"
                :append-icon="showConPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConPassword = !showConPassword"
                outlined
              />
            </v-col>
            <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
              <span class="label-class required">{{
                $t("message.label-phone")
              }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
              <v-text-field
                v-model="formEdit.phone"
                :error-messages="phoneNumberErrors"
                required
                @input="$v.formEdit.phone.$touch()"
                @blur="$v.formEdit.phone.$touch()"
                outlined
              />
            </v-col>
            <v-col cols="12" md="2" xs2 class="label-class pb-0 ">
              <span class="required">{{ $t("message.label-sex") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs2 class="input-class pb-0 ">
              <v-radio-group v-model="formEdit.gender" row mandatory>
                <v-radio v-for="gender in selectedGender" :key="gender.id" :value="gender.id" color="#ff0090" :label="gender.label" />
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="2" xs2 class="label-class pb-0 ">
              <span class="required">{{ $t("message.label-bod") }}</span>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pb-0 ">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="formEdit.bod"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formEdit.bod"
                    append-icon="mdi-calendar"
                    :error-messages="birthdayErrors"
                    @input="$v.formEdit.bod.$touch()"
                    @blur="$v.formEdit.bod.$touch()"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    color="#ff0090"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="formEdit.bod" :max="maxDate()" scrollable color="#ff0090">
                  <v-spacer></v-spacer>
                  <v-btn text color="#ff0090" @click="modal = false">
                    {{ $t("message.btn-cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="#ff0090"
                    @click="$refs.dialog.save(formEdit.bod)"
                  >
                    {{ $t("message.btn-ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <div class="d-flex flex-row-reverse mt-3 me-0" tile>
            <div class="m-2">
              <v-btn
                color="#E93B81"
                style="color: white"
                type="submit"
                @click.prevent="handleEditAccount"
              >
                {{ $t("message.btn-edit") }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-col>
      <SnackBar v-if="openSnackbar" :snackbar="openSnackbar" :message="$t('message.title-edit-account-success-message')" />
    </v-container>
    <Sidebar />
  </div>
</template>

<script>
// import Sidebar from "../components/Sidebar.vue";
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Sidebar from '../components/developmentv2/Sidebar.vue'
import Back from "../components/Back.vue";
import SnackBar from "../components/Snackbar.vue";

import { validationMixin } from "vuelidate";
import { required, numeric, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "EditAccount",
  mixins: [validationMixin],
  validations: {
    formEdit: {
      name: { required },
      furigana: { required },
      phone: { required, numeric: numeric },
      gender: { required },
      bod: { required },
      oldPassword: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  components: {
    Sidebar,
    BackComponent: Back,
    SnackBar,
    HeaderPage
  },
  data: () => ({
    dataErr: "",
    errMsg: "",
    formEdit: {
      name: "",
      furigana: "",
      bod: null,
      gender: null,
      phone: "",
      oldPassword: null,
      password: null,
      confirmPassword: null,
    },

    // openSnackbar: false,
    menu: false,
    modal: false,
    menu2: false,
    showPassword: false,
    showConPassword: false,
  }),
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'ja'
    },
    handleEditAccount() {
      this.$store.dispatch("account_module/updateAccount", this.formEdit);
    },
    maxDate() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
      const timestamp = date;
      return timestamp;
    },
    async dispatchUserProfile() {
      await this.$store.dispatch("account_module/fetchUserProfile");
      let resultUser = this.$store.state.account_module.userProfile;

      this.formEdit.name = resultUser.customer_name;
      this.formEdit.furigana = resultUser.furigana;
      this.formEdit.bod = resultUser.bod;
      this.formEdit.gender = resultUser.gender;
      this.formEdit.phone = resultUser.phone;
      this.formEdit.oldPassword = resultUser.oldPassword;
      this.formEdit.password = resultUser.password;
      this.formEdit.confirmPassword = resultUser.confirmPassword;
    },
  },
  computed: {
    selectedGender() {
      return [
        { id: 1, label: this.$t("message.label-male") },
        { id: 2, label: this.$t("message.label-female") }
      ]
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formEdit.name.$dirty) return errors;
      !this.$v.formEdit.name.required &&
        errors.push(this.$t("message.err-msg-name-register"));
      return errors;
    },
    furiganaErrors() {
      const errors = [];
      if(!this.$v.formEdit.furigana.$dirty) return errors;
      !this.$v.formEdit.furigana.required &&
        errors.push("The field furigana is required")
      return errors
    },
    openSnackbar() {
      return this.$store.state.account_module.openSnackbar;
    },
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.formEdit.oldPassword.$dirty) return errors;
      !this.$v.formEdit.oldPassword.minLength &&
        errors.push(this.$t("message.err-msg-password-minLength"));
      //   !this.$v.password.required && errors.push(this.$t("message.err-msg-password-required"));
      //   return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.formEdit.password.$dirty) return errors;
      !this.$v.formEdit.password.minLength &&
        errors.push(this.$t("message.err-msg-password-minLength"));
      //   !this.$v.password.required && errors.push(this.$t("message.err-msg-password-required"));
      //   return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.formEdit.confirmPassword.$dirty) return errors;
      !this.$v.formEdit.confirmPassword.sameAsPassword &&
        errors.push(this.$t("message.err-msg-password-same"));
      //   !this.$v.password. && errors.push(this.$t("message.err-msg-confirm-password-required"));
      //   return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.formEdit.phone.$dirty) return errors;
      !this.$v.formEdit.phone.required &&
        errors.push(this.$t("message.err-msg-phone-number-required"));
      !this.$v.formEdit.phone.numeric &&
        errors.push(this.$t("message.err-msg-phone-number-numeric"));
      return errors;
    },
    sexErrors() {
      const errors = [];
      if (!this.$v.formEdit.gender.$dirty) return errors;
      !this.$v.formEdit.gender.required &&
        errors.push(this.$t("message.err-msg-sex-required"));
      return errors;
    },
    birthdayErrors() {
      const errors = [];

      if (!this.$v.formEdit.bod.$dirty) return errors;
      !this.$v.formEdit.bod.required &&
        errors.push(this.$t("message.err-msg-bod-required"));
      return errors;
    },
  },
  mounted() {
    this.dispatchUserProfile();
    this.changeLocale()
  },
};
</script>

<style>
.required:after {
  content: " *";
  color: red;
}
</style>
